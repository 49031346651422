// import { useEffect, useState } from "react";
import "./App.css";
// import Home from "./components/Home";
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import * as $ from "jquery";

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <div className="container align-items-start">
          <div className="row">
            <div className="col-12">
              <h1>Relevant Local Media API</h1>
            </div>
            <div className="col-12">
              <button
                className="btn btn-primary"
                onClick={() => {
                  async function go() {
                    let netlifyFunc = "/.netlify/functions/onboard";
                    let onboard = await $.ajax({
                      type: "POST",
                      url: `${netlifyFunc}`,
                      json: true,
                      data: {
                        ads: "False",
                        name: "abcdef",
                        online: "False",
                        package: "Enhanced Visibility",
                        salesperson: "ZACH!!",
                        social: "false",
                        website: "true",
                        email: "true",
                      },
                      error: function (xhr, status, error) {
                        console.log(xhr.responseText);
                      },
                      success: async (data) => {
                        console.log(await data);
                        return await data;
                      },
                    });
                    return onboard;
                  }
                  go();
                }}
              >
                ONBOARD
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  async function go() {
                    let netlifyFunc = "/.netlify/functions/modify";
                    let modify = await $.ajax({
                      type: "POST",
                      url: `${netlifyFunc}`,
                      json: true,
                      data: {
                        ads: "true",
                        name: "abcdef",
                        online: "true",
                        package: "Basic",
                        salesperson: "Zach",
                        social: "false",
                        website: "false",
                      },
                      error: function (xhr, status, error) {
                        console.log(xhr.responseText);
                      },
                      success: async (data) => {
                        console.log(await data);
                        return await data;
                      },
                    });
                    return modify;
                  }
                  go();
                }}
              >
                MODIFY
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  async function go() {
                    let netlifyFunc = "/.netlify/functions/hire";
                    let modify = await $.ajax({
                      type: "POST",
                      url: `${netlifyFunc}`,
                      json: true,
                      data: {
                        name: "hire newhire",
                        role: "Onboarding Specialist",
                      },
                      error: function (xhr, status, error) {
                        console.log(xhr.responseText);
                      },
                      success: async (data) => {
                        console.log(await data);
                        return await data;
                      },
                    });
                    return modify;
                  }
                  go();
                }}
              >
                NEW HIRE
              </button>
              <p>/onboard: New onboarding client</p>
              <p>/modify: Existing client</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
